import React from "react";
import logo from "../assets/logo.png";
import { Button, Typography } from "@mui/material";
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';

const Footer = () => {
  return (
    <section className="relative overflow-hidden py-10" style={{background:"#00BCFD"}}>
      <div className="relative z-10 mx-auto max-w-7xl px-4">
        <div className="-m-6 flex flex-wrap">
          <div className="w-full p-6 md:w-1/2 lg:w-5/12">
            <div className="flex h-full flex-col justify-between">


            
              <div className="mb-4 inline-flex items-center w-20 md:w-30">
              <img style={{cursor:"pointer"}}src={"/stalwart-logo.webp"}  onClick={()=>window.location.href="https://www.stalwartprojects.in/"}/>
          
                <img src={logo} alt="logo" />
               
              </div>
              <span className=" text-lg font-bold">Hotel Stalwart Jajati</span>

              <div>
              Mobile : 9237385044
              </div>

              <div>
              Landline:  0 674 353 5756, 0 674 353 5959
              </div>

              <div>
              Mail: reservation.jajati@stalwartprojects.in
         
              </div>

              <div>
               {/* <a  href="https://pixelai.in/"> <p className="mt-4  text-base font-medium cursor-pointer">
                Made with ❤️ by Pixelai.in
                </p></a> */}
                <p className="text-sm">
                  &copy; Copyright 2024. All Rights Reserved by Hotel Stalwart Jajati.
                </p>
              </div>
            </div>
          </div>
          <div className="w-full p-6 md:w-1/2 lg:w-2/12">
            <div className="h-full">
              <Typography style={{fontSize:"18px", fontWeight:"600", marginBottom:"10px"}}>Pages</Typography>
                
             
              <ul>
                <li className="mb-4" style={{cursor:"pointer"}} onClick={()=>window.location.href="/"}>Home</li>
                <li className="mb-4"style={{cursor:"pointer"}} onClick={()=>window.location.href="/services"}>Services</li>
                <li className="mb-4" style={{cursor:"pointer"}} onClick={()=>window.location.href="/rooms-and-suits"}>Room & Suite</li>
                <li className="mb-4" style={{cursor:"pointer"}} onClick={()=>window.location.href="/about-us"}>About us</li>
                <li className="mb-4" style={{cursor:"pointer"}} onClick={()=>window.location.href="/gallery"}>Gallery</li>
               
              </ul>
            </div>
          </div>
          <div className="w-full p-6 md:w-1/2 lg:w-2/12">
            {/* <div className="h-full">
              <h3 className="tracking-px mb-9  text-xs font-semibold uppercase ">
                Support
              </h3>
              <ul>
               
                <li className="mb-4">Help</li>
                <li className="mb-4">Contact Us</li>
              </ul>
            </div> */}
          </div>
          <div className="w-full p-6 md:w-1/2 lg:w-3/12">
            {/* <div className="h-full">
              <h3 className="tracking-px mb-9  text-xs font-semibold uppercase ">
                Legals
              </h3>
              <ul>
                <li className="mb-4">Terms &amp; Conditions</li>
                <li className="mb-4">Privacy Policy</li>
               
              </ul>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
