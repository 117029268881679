import { Box, Button } from "@mui/material";
import { useState } from "react";
import { primary } from "../SDK/theme";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import go from "../assets/stalwart/go.png";
import my from "../assets/stalwart/mytrip.png";
import booking from "../assets/stalwart/booking.webp";

const Showcase = ({ img, title, desc, button }) => {
  const [isHovered, setIsHovered] = useState(false);

  const imageStyle = {
    padding: "8px",
    borderRadius: "8px",
    transition: "transform 0.3s ease-in-out",
    transform: isHovered ? "scale(1.2)" : "scale(1)",
  };
  const handleClick = () => {
    window.open("https://stalwartjajati.pripgo.com/", "_blank", "noopener,noreferrer");
  };
  return (
    <div className="text-[#fcf2de] flex flex-col items-center p-2 gap-2 md:w-1/3 pb-6">
      {/* <img className='p-8 rounded ' src={img} alt=""  /> */}

      <div
        style={{ display: "inline-block" }} // Adjust display property as needed
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <img style={imageStyle} src={img} alt="" />
      </div>
      <h3 className="text-xl">{title}</h3>
      <p className="text-[12px] text-center p-8" style={{ height: "200px" }}>
        {desc}
      </p>

      <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
        <Button
          key={5}
          sx={{
            color: primary?.main,
            //margin: "0em 0.5em 0em 0.5em",
            boxShadow: "none",
            border: "none",
            fontWeight: "500",
            background: primary.white,
            justifyContent: "space-between",
          }}
          onClick={handleClick}
          // href="https://stalwartjajati.pripgo.com/"
          // hrefLang={"en"}
          // target="_blank"
          // rel="noreferrer"
          // underline="none"
        >
          <PhoneInTalkIcon
            sx={{
              //color: "white",
              marginRight: "2px",
            }}
          />{" "}
          {button}
        </Button>
        <a href="https://www.goibibo.com/hotels/stalwart-jajati-hotel-in-bhubaneshwar-1449863262364239782/">
          <img
            alt="jajati-go"
            src={`${go}`}
            style={{
              width: "40px",
              maxHeight: "40px",
              objectFit: "cover",
              margin: "6px",
              cursor: "pointer",
            }}
          />
        </a>
        <a href="https://www.makemytrip.com/hotels/hotel_stalwart_jajati-details-bhubaneshwar.html">
          <img
            alt="jajati-my"
            src={`${my}`}
            style={{
              width: "40px",
              height: "40px",
              objectFit: "cover",
              margin: "6px",
              borderRadius: "50%",
              cursor: "pointer",
            }}
          />
        </a>
        <a href="https://www.booking.com/hotel/in/stalwart-jajati.html">
          <img
            alt="jajati-booking"
            src={`${booking}`}
            style={{
              width: "40px",
              maxHeight: "40px",
              objectFit: "cover",
              margin: "6px",
              borderRadius: "50%",
              cursor: "pointer",
            }}
          />
        </a>
      </Box>
      {/* <button className="px-6 p-2 bg-[#fcf2de] uppercase text-[#d27548] font-normal rounded hover:bg-orange-300 ease-linear hover:text-[#000000cd]">
            {button}
        </button> */}
    </div>
  );
};

export default Showcase;
